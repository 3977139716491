<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">About Unified.</h1>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="column is-full feature-content">
        <h2 class="title is-size-2">Our Story.</h2>
        <h3 class="is-size-5">
          Unified was established in 2002 and since then we have specialised in
          the design, development, implementation and support of web solutions
          and related services.
          <br /><br />
          We have successfully implemented a diverse range of web solutions and
          services to many different industries and sectors, with clients
          including:
          <br /><br />
          <hr />
          <div class="columns">
            <div class="column is-half-tablet is-full-mobile">
              <li>
                <span>WA State Government</span> for numerous WA State
                Government agency sites and Intranets;
              </li>
              <br /><br />
              <li>
                <span
                  >Department of Local Government and Regional Development
                  WA</span
                >
                for over 110 Community Resource Centre websites across the
                state;
              </li>
              <br /><br />
              <li>
                <span>Lotterywest</span> for both their internal Intranet and
                public website site which is a HitWise Top #5 ranked site in
                Australia for traffic;
              </li>
              <br /><br />
              <li>
                <span>WA Local Government Association (WALGA)</span> for over 80
                local council websites across WA as well as for their central
                web portal site;
              </li>
              <br /><br />
              <li>
                <span>Western Australian Land Information System (WALIS)</span>
                for their public website;
              </li>
            </div>
            <div class="column is-half-tablet is-full-mobile">
              <li>
                <span>NSW State Government</span> for numerous NSW State
                Government agency websites and Intranets;
              </li>
              <br /><br />
              <li>
                <span>NSW Government Chief Information Office</span> for the
                shared Plone CMS agency platform system;
              </li>
              <br /><br />
              <li>
                <span
                  >NSW Department of Services, Technology and
                  Administration</span
                >
                for the Government Licensing System and RTA for the “OneGov”
                Direct Access System;
              </li>
              <br /><br />
              <li>
                <span>Gosford City Council NSW</span> for their corporate
                website, Intranet &amp; eTendering/DA/LEP systems; and
              </li>
              <br /><br />
              <li>
                <span>Toyota WA</span> for their integrated Intranet and dealer
                management Extranet systems.
              </li>
            </div>
          </div>
        </h3>
      </div>
    </section>
  </div>
</template>

<style type="text/css" scoped>
@import "../styles/feature-content.scss";
span {
  font-weight: bold;
}
.hero-body {
  background: url("/img/banner-office.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.83);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/banner-office.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.83);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "About"
}
</script>
